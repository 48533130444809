import * as R from 'ramda'
import {clearHtmlAndshrink} from 'helpers/utils'
import {graphql} from 'gatsby'
import {pageContextPropTypes} from 'helpers/propTypes'
import {shape} from 'prop-types'
import React from 'react'

import Box from 'components/UI/Box'
import FlexBoxColumnsLayout from 'components/UI/Layouts/FlexBoxColumnsLayout'
import HeroRichText from 'components/UI/HeroRichText'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import SimpleCard from 'components/CommittedEmployees/Card'
import Title from 'components/UI/Title'

const SolidarityPrograms = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)

  const {
    metaTitle,
    metaDescription,
    mainTitle,
    kpis,
    programs,
    image,
    title,
    shortDescription,
  } = R.pathOr(null, ['allContentfulSolidarityPrograms', 'nodes', 0], data)

  // const topPrograms = R.slice(0, 3, programs)
  // const subPrograms = R.slice(3, programs.length, programs)

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <Section hasPaddingTop={false} hasPaddingBottom={false}>
        <HeroRichText
          title={title}
          titleType="title"
          description={shortDescription}
          descriptionType="description"
          media={image}
          hasAutoWidth
        />
      </Section>
      <Section>
        <Title variant="h2" type="subTitle">
          {mainTitle}
        </Title>
        <FlexBoxColumnsLayout>
          {R.map(
            program => (
              <SimpleCard
                id={program?.contentful_id}
                link={program?.slug}
                title={program?.title}
                description={clearHtmlAndshrink(
                  program?.introduction?.introduction,
                )}
                image={program?.image}
                partner={program?.partner}
                hasPartner
                hasArrow
              />
            ),
            programs,
          )}
        </FlexBoxColumnsLayout>
      </Section>
      {/* <Section hasSmallPadding>
        <Box kpis={kpis} isWide />
      </Section> */}
      {/* <Section hasPaddingTop={false}>
        <FlexBoxColumnsLayout>
          {R.map(
            program => (
              <div style={{marginBottom: 50}}>
                <SimpleCard
                  id={program?.contentful_id}
                  link={program?.slug}
                  title={program?.title}
                  description={clearHtmlAndshrink(
                    program?.introduction?.introduction,
                  )}
                  image={program?.image}
                  partner={program?.partner}
                  hasPartner
                  hasArrow
                />
              </div>
            ),
            subPrograms,
          )}
        </FlexBoxColumnsLayout>
      </Section> */}
    </>
  )
}

SolidarityPrograms.propTypes = {
  data: shape({}).isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

export const pageQuery = graphql`
  query templateSolidarityPrograms(
    $nodeLocale: String
    $technicalName: String
    $entityRegEx: String
  ) {
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      id
      name
      ...countryDataFields
      salesforce
    }
    allContentfulSolidarityPrograms(
      filter: {node_locale: {eq: $nodeLocale}, slug: {regex: $entityRegEx}}
    ) {
      nodes {
        ...solidarityProgramsFields
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`

export default SolidarityPrograms
